import {
    each, flatten, flow, isFunction, isObject, map, tap, toPairs,
} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {fork} from 'redux-saga/effects';

import {Sagas as PlansSagas} from 'plans';
import {Sagas as UserSagas} from 'user';

interface SagaModule {[_: string]: SagaIterator;}

export default function* combineSagas(): Iterator<SagaIterator[]> {
    const validateInput = each((obj?: object) => {
        if (!isObject(obj)) {
            throw new Error(`Expected an array of objects, but found an item of type ${typeof obj}.`);
        }
    });
    const validateNameSagaPair = ([name, saga]: [string, unknown]) => {
        if (!isFunction(saga)) { throw new Error(`The key ${name} is not a saga.`); }
    };
    const sagas = [
        PlansSagas,
        UserSagas,
    ] as unknown[] as SagaModule[];
    const process: (sagasModules: SagaModule[]) => SagaIterator[] = flow([
        tap(validateInput),
        map(toPairs),
        flatten,
        tap(each(validateNameSagaPair)),
        map(([, saga]: [string, () => SagaIterator]) => fork(saga)),
    ]);
    yield process(sagas);
}
