import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Field, Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms} from 'forms';
import {Components as Layout} from 'layout';
import {PropsForForm} from '../../../forms/components/withForm';
import {LoginFormValues} from '../../types/LoginFormValues';
import {validate} from './validations';

import styles from './styles.sass';

interface OwnProps {}

type Props = PropsForForm<LoginFormValues, OwnProps>;

class LoginForm extends Component<Props> {
    render(): ReactNode {
        const {
            FormField,
            handleSubmit,
            valid,
            submitting,
        } = this.props;
        return (
            <Layout.Panel caption="">
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    {submitting &&
                     <div className={`${styles['loader-wrapper']}`}>
                         <div
                             className={`${styles.loader}`}
                         />
                     </div>
                    }
                    <div
                        className="mt-1 mb-3"
                    >
                        <Field
                            className="mt-2"
                            name="username"
                            type="text"
                            component={FormField}
                            label="Uživatelské jméno"
                        />
                    </div>
                    <Field
                        className="mt-2"
                        name="password"
                        type="password"
                        component={FormField}
                        label="Heslo"
                    />
                    <div className="d-flex justify-content-end mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid}
                            type="submit"
                        >
                            Přihlásit
                        </Buttons.Button>

                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<LoginFormValues, OwnProps, Props>(LoginForm, {
    form: 'login',
    validate,
});
