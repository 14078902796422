import {debounce} from 'lodash/fp';
import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Async as ReactSelect, LoadOptionsLegacyHandler, Option} from 'react-select';

import classNames from 'utils/classNames';
import {stringTuple} from 'utils/stringTuple';
import {BaseFieldType} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';

import 'react-select/dist/react-select.css';
import './styles.sass';

type Options<T> = Option<T>[];

type LoadOptionsCallback<OptionT> =
    (error: string | null, data: { options: Options<OptionT>; complete: boolean }) => void;

export const selectFieldValidTypes = stringTuple('select');
export type SelectFieldValidType = typeof selectFieldValidTypes[number];

export type SelectedValue<OptionT> = Option<OptionT> | Option<OptionT>[] | null;

export type SelectFieldType<ActionParam, OptionT> = {
    type: SelectFieldValidType;
    input: {
        name: string;
        value: SelectedValue<OptionT>;
        onChange(_: SelectedValue<OptionT>): void;
        onBlur(_: SelectedValue<OptionT>): void;
    };
    async?: boolean;
    debounceTime?: number;
    asyncMinLength?: number;
    options: Options<OptionT>;
    multi: boolean;
    loadOptions?(filter: string, cb: LoadOptionsCallback<OptionT>): void;
} & BaseFieldType<ActionParam>;

type Props<ActionParam, OptionT> = {
    field: SelectFieldType<ActionParam, OptionT>;
} & ChildProps;

class Select<ActionParam, OptionT> extends Component<Props<ActionParam, OptionT>> {
    handleLoadOptions = (value: string, cb: LoadOptionsCallback<OptionT>) => {
        const {field} = this.props;
        const {loadOptions, async, options} = field;
        if (async) {
            if (!loadOptions) {
                throw new Error('async without loadOptions');
            }
            loadOptions(value, cb);
        } else {
            setTimeout(() => cb(null, {options, complete: false}));
        }
    }

    render(): ReactNode {
        const {className, field} = this.props;

        const {
            label,
            helpText,
            input,
            options,
            meta,
            disabled,
            multi,
            debounceTime,
            asyncMinLength,
        } = field;

        const {asyncValidating, submitting} = meta;

        const classes = classNames('form-group', 'w-100', className);

        const debouncedHandleLoadOptions = debounce(debounceTime || 500, this.handleLoadOptions);
        const handleLoadOptions: LoadOptionsLegacyHandler<OptionT> =
            (value: string, cb: LoadOptionsCallback<OptionT>) => {
                if (value.length < (asyncMinLength || 3)) {
                    setTimeout(() => cb(null, {options, complete: false}));
                } else {
                    debouncedHandleLoadOptions(value, cb);
                }
            };

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>

                <ReactSelect
                    className="Field-Select"
                    value={input.value || undefined}
                    onChange={input.onChange}
                    onBlur={() => input.onBlur(input.value)}
                    options={options}
                    placeholder={label}
                    id={input.name}
                    disabled={asyncValidating || submitting || disabled}
                    multi={multi}
                    loadOptions={handleLoadOptions}
                />
            </div>
        );
    }
}

export default Select;
