import {reset, startSubmit, stopSubmit} from 'redux-form';
import {SagaIterator} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';

import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {convertErrorFromBeToFormError} from '../../notifications/utils/convertErrorFromBeToFormError';
import showSuccess from '../../notifications/utils/showSuccess';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {CreateNewStableAction, createNewStableError, createNewStableSuccess} from '../actions';
import Api from '../api';

function* execute(action: CreateNewStableAction): SagaIterator {
    const {payload: {stable}} = action;
    const formName = 'newStable';
    const title = 'Vytvoření stáje';

    yield put(startSubmit(formName));

    const response: CResponse<void> = yield call(Api.createNewStable, stable);
    const {status, data} = response;

    if (status !== 204) {
        const err = data as ErrorFromBE;
        handleResponseError(response, title);
        yield put(createNewStableError(err));
        return yield put(stopSubmit(formName, convertErrorFromBeToFormError(err)));
    }

    showSuccess(title, `Stáj ${stable.stableName} vytvořena.`);
    yield put(createNewStableSuccess());
    yield put(stopSubmit(formName));
    return yield put(reset(formName));
}

function* createNewStableSaga(): SagaIterator {
    yield takeLatest('home/CREATE_NEW_STABLE', execute);
}

export default createNewStableSaga;
