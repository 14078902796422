import {ActivePlan} from '../types/ActivePlan';
import {ErrorFromBE} from '../types/ErrorFromBE';
import {ImportedPlan} from '../types/ImportedPlan';
import {NewStable} from '../types/NewStable';
import {User} from '../types/User';

export interface GetActivePlansAction {
    type: 'home/GET_ACTIVE_PLANS';
}

export const getActivePlans = (): GetActivePlansAction => ({
    type: 'home/GET_ACTIVE_PLANS',
});


export interface GetActivePlansSuccessAction {
    type: 'home/GET_ACTIVE_PLANS_SUCCESS';
    payload: ActivePlan[];
}

export const getActivePlansSuccess = (payload: ActivePlan[]): GetActivePlansSuccessAction => ({
    type: 'home/GET_ACTIVE_PLANS_SUCCESS',
    payload,
});


export interface GetActivePlansErrorAction {
    type: 'home/GET_ACTIVE_PLANS_ERROR';
    payload: ErrorFromBE;
}

export const getActivePlansError = (err: ErrorFromBE): GetActivePlansErrorAction => ({
    type: 'home/GET_ACTIVE_PLANS_ERROR',
    payload: err,
});

// ---

export interface UploadPlanActionPayload { plan: File; }

export interface UploadPlanAction {
    type: 'home/UPLOAD_PLAN';
    payload: UploadPlanActionPayload;
}

export const uploadPlan = ({ plan }: UploadPlanActionPayload): UploadPlanAction => ({
    type: 'home/UPLOAD_PLAN',
    payload: { plan },
});


export interface UploadPlanSuccessAction {
    type: 'home/UPLOAD_PLAN_SUCCESS';
    payload: ImportedPlan;
}

export const uploadPlanSuccess = (payload: ImportedPlan): UploadPlanSuccessAction => ({
    type: 'home/UPLOAD_PLAN_SUCCESS',
    payload,
});


export interface UploadPlanErrorAction {
    type: 'home/UPLOAD_PLAN_ERROR';
    payload: ErrorFromBE;
}

export const uploadPlanError = (err: ErrorFromBE): UploadPlanErrorAction => ({
    type: 'home/UPLOAD_PLAN_ERROR',
    payload: err,
});

// ---

export interface ExportActionPayload {
    urlWithFilter: string;
}
export interface ExportAction {
    type: 'home/EXPORT';
    payload: ExportActionPayload;
}

export const exportAction = (payload: ExportActionPayload): ExportAction => ({
    type: 'home/EXPORT',
    payload,
});


export interface ExportSuccessAction {
    type: 'home/EXPORT_SUCCESS';
    payload: ArrayBuffer;
}

export const exportSuccess = (payload: ArrayBuffer): ExportSuccessAction => ({
    type: 'home/EXPORT_SUCCESS',
    payload,
});


export interface ExportErrorAction {
    type: 'home/EXPORT_ERROR';
    payload: ErrorFromBE;
}

export const exportError = (err: ErrorFromBE): ExportErrorAction => ({
    type: 'home/EXPORT_ERROR',
    payload: err,
});

// ---

export interface CreateNewStableActionPayload {
    stable: NewStable;
}
export interface CreateNewStableAction {
    type: 'home/CREATE_NEW_STABLE';
    payload: CreateNewStableActionPayload;
}

export const createNewStableAction =
    (payload: CreateNewStableActionPayload): CreateNewStableAction => ({
        type: 'home/CREATE_NEW_STABLE',
        payload,
    });


export interface CreateNewStableSuccessAction {
    type: 'home/CREATE_NEW_STABLE_SUCCESS';
}

export const createNewStableSuccess = (): CreateNewStableSuccessAction => ({
    type: 'home/CREATE_NEW_STABLE_SUCCESS',
});


export interface CreateNewStableErrorAction {
    type: 'home/CREATE_NEW_STABLE_ERROR';
    payload: ErrorFromBE;
}

export const createNewStableError = (err: ErrorFromBE): CreateNewStableErrorAction => ({
    type: 'home/CREATE_NEW_STABLE_ERROR',
    payload: err,
});

// ---

export interface GetUsersAction {
    type: 'home/GET_USERS';
}

export const getUsers = (): GetUsersAction => ({
    type: 'home/GET_USERS',
});


export interface GetUsersSuccessAction {
    type: 'home/GET_USERS_SUCCESS';
    payload: User[];
}

export const getUsersSuccess = (payload: User[]): GetUsersSuccessAction => ({
    type: 'home/GET_USERS_SUCCESS',
    payload,
});


export interface GetUsersErrorAction {
    type: 'home/GET_USERS_ERROR';
    payload: ErrorFromBE;
}

export const getUsersError = (err: ErrorFromBE): GetUsersErrorAction => ({
    type: 'home/GET_USERS_ERROR',
    payload: err,
});

// ---

export type PlansAction =
    GetActivePlansAction | GetActivePlansSuccessAction | GetActivePlansErrorAction |
    UploadPlanAction | UploadPlanSuccessAction | UploadPlanErrorAction |
    ExportAction | ExportErrorAction | ExportSuccessAction |
    CreateNewStableAction | CreateNewStableErrorAction | CreateNewStableSuccessAction |
    GetUsersAction | GetUsersSuccessAction | GetUsersErrorAction;
