import * as React from 'react';
import {Component, ReactNode} from 'react';

import classNames from 'utils/classNames';
import {stringTuple} from '../../../../utils/stringTuple';
import {BaseFieldType} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

export type TextareaFieldType<ActionParam> = {
    type: TextareaFieldValidType;
    rows?: number;
    input: {
        name: string;
    };
} & BaseFieldType<ActionParam>;

export const textareaFieldValidTypes = stringTuple('textarea');
export type TextareaFieldValidType = typeof textareaFieldValidTypes[number];

type Props<ActionParam> = {
    field: TextareaFieldType<ActionParam>;
} & ChildProps;

class Textarea<ActionParam> extends Component<Props<ActionParam>> {
    render(): ReactNode {
        const {className, field} = this.props;
        const {
            label,
            helpText,
            input,
            rows,
            meta,
        } = field;

        const {asyncValidating, submitting} = meta;

        const classes = classNames('form-group', 'w-100', className);

        return (
            <div className={classes}>
                <Label htmlFor={input.name}>
                    {label}
                </Label>

                <HelpText>
                    {helpText}
                </HelpText>

                <textarea
                    rows={rows}
                    id={input.name}
                    className="form-control"
                    disabled={asyncValidating || submitting}
                />
            </div>
        );
    }
}

export default Textarea;
