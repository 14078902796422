import { flow } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';

import { login } from '../actions';
import LoginForm from '../components/loginForm';

import {Dispatch} from 'redux';
import styles from './styles.sass';

interface Props {
    handleLogin(): void;
}

const Login = (props: Props) => {
    const { handleLogin } = props;
    return (
        <div
            className="d-flex mt-5 pt-5 flex-column align-items-center"
        >
            <div className={`${styles.inner}`}>
                <h1>Přihlášení</h1>
                <div>
                    <LoginForm
                        onSubmit={handleLogin}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLogin: () => dispatch(login()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Login) as typeof Login;
