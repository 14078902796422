import {flow, map, reverse} from 'lodash/fp';
import {Option as SelectOption} from 'react-select';

import formatDateForBe from 'utils/formatDateForBe';
import unfoldr from 'utils/unfoldr';

export interface MonthYear {
    month: number;
    year: number;
}

export interface LabelWithMonthYear {
    label: string;
    monthYear: MonthYear;
}

export class ExportFormOptionsGeneration {
    static convertMonthYearLabelToFilter(x: LabelWithMonthYear): SelectOption<string> {
        const from = new Date(x.monthYear.year, x.monthYear.month - 1, 1, 0, 0, 0, 0);
        const to = new Date(from.getTime());
        to.setMonth(to.getMonth() + 1);
        to.setDate(to.getDate() - 1);
        to.setHours(23, 59, 59, 999);
        return {
            label: x.label,
            value: `from=${formatDateForBe(from)}&to=${formatDateForBe(to)}`,
        };
    }

    static genExportOptions(startDate: MonthYear, stopDate: MonthYear): SelectOption<string>[] {
        return flow([
            this.monthsRange,
            map(ExportFormOptionsGeneration.convertMonthYearLabelToFilter),
            reverse,
        ])([startDate, stopDate]);
    }

    static monthsRange([start, stop]: [MonthYear, MonthYear]): LabelWithMonthYear[] {
        return unfoldr(
            (s: MonthYear) => {
                if (s.year * 12 + s.month > stop.year * 12 + stop.month) {
                    return null;
                }
                const monthOverflow = s.month === 12;
                const nextMY = {
                    month: monthOverflow ? 1 : s.month + 1,
                    year: s.year + (monthOverflow ? 1 : 0),
                };
                return [
                    {monthYear: s, label: `${s.month} / ${s.year}`},
                    nextMY,
                ];
            },
            start,
        );
    }
}
