import * as React from 'react';
import {ComponentClass, ComponentType, ReactNode} from 'react';
import {Dispatch} from 'redux';
import {reduxForm} from 'redux-form';
import {InjectedFormProps} from 'redux-form/lib/reduxForm';

import Field from '../Field';

export type ErrorType = string;

type Errors<FormData> = { [_ in keyof FormData]?: string };

interface Opts<FormData, P> {
    form: string;
    asyncChangeFields?: string[];
    asyncBlurFields?: string[];
    initialValues: Partial<FormData>;
    enableReinitialize: boolean;
    destroyOnUnmount: boolean;
    forceUnregisterOnUnmount: boolean;
    keepDirtyOnReinitialize: boolean;

    validate(values: FormData, props: P): Errors<FormData>;

    asyncValidate?(
        values: FormData,
        dispatch: Dispatch,
        props: P & InjectedFormProps<FormData, P, ErrorType>,
        blurredField: string,
    ): Promise<Errors<FormData>>;
}

export type PropsForForm<FormData, OwnProps> = WithFormInjectedProps<FormData> &
    InjectedFormProps<FormData, OwnProps, ErrorType> & OwnProps;

export interface WithFormInjectedProps<FormData> extends WithFormOuterInjectedProps<FormData> {
    FormField: any; // FormFieldType; // TODO

    renderErrors(): ReactNode;
}

export interface WithFormOuterInjectedProps<FormData> {
    onSubmit?(_: FormData): void;
}

type OuterProps<FormData, OwnProps> = WithFormOuterInjectedProps<FormData> & OwnProps;

const withForm = <FormData,
    OwnProps,
    P extends WithFormInjectedProps<FormData> & InjectedFormProps<FormData, OwnProps, ErrorType>>
(
    WrappedComponent: ComponentClass<P>,
    opts: Partial<Opts<FormData, P>>,
) => {
    const {
        form,
        validate,
        asyncValidate,
        asyncChangeFields,
        asyncBlurFields,
        initialValues,
        enableReinitialize,
        destroyOnUnmount = true,
        forceUnregisterOnUnmount = false,
        keepDirtyOnReinitialize = false,
    } = opts;

    const WithForm = (props: P) => {
        const renderErrors = () => {
            const {error} = props;
            if (!error) { return null; }

            return <div className="alert alert-danger">{error}</div>;
        };

        return (
            <WrappedComponent
                {...props}
                FormField={Field}
                renderErrors={renderErrors}
            />
        );
    };

    return reduxForm({
        asyncValidate,
        asyncChangeFields,
        asyncBlurFields,
        form,
        validate,
        enableReinitialize,
        initialValues,
        destroyOnUnmount,
        forceUnregisterOnUnmount,
        keepDirtyOnReinitialize,
    })(WithForm) as unknown as ComponentType<OuterProps<FormData, OwnProps>>;
};

export default withForm;
