import {flatten} from 'lodash/fp';
import * as React from 'react';
import {ReactNode} from 'react';

import applyIfDefined from 'utils/applyIfDefined';
import {Column} from '../../types/Column';
import {Filter} from '../../types/Filter';
import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import Filters from '../Filters';
import Pagination from '../Pagination';

import styles from './styles.sass';

export type Row<T> = {
    id: number;
    selected?: boolean;
    exportedByAssociation?: boolean;
} & T;

interface Props<RowT> {
    children?: ReactNode;
    columns: Column<RowT>[];
    hasHeader?: boolean;
    hasFooter?: boolean;
    rows: Row<RowT>[];
    total?: number;
    page?: number;
    pageSize?: number;
    filter?: Filter;
    orderBy?: string;
    orderDesc?: boolean;
    emptyMessage?: ReactNode;
    rowHighlight?: boolean;
    highlightedRow?: number;
    twoMainColumns?: boolean;
    handleFilterChange?: FilterChangeHandler;

    handlePageChange?(page: number): void;

    handleOrderChange?(accessor: string): void;

    handleFilterSubmit?(): void;

    handleFilterClear?(): void;

    handleHighlightRow?(id: number): void;
}

const defaultProps = {
    hasHeader: true,
    hasFooter: true,

};

type OrderDir = 'desc' | 'asc';

const Table = <RowT extends {}>(props: Props<RowT>) => {
    const {
        children,
        columns,
        hasHeader: rawHasHeader,
        hasFooter: rawHasFooter,
        rows,
        total: rawTotal,
        filter,
        orderBy,
        orderDesc,
        emptyMessage,
        handlePageChange,
        handleOrderChange,
        handleFilterChange,
        handleFilterSubmit,
        handleFilterClear,
        rowHighlight,
        handleHighlightRow,
        highlightedRow,
        page: rawPage,
        pageSize: rawPageSize,
        twoMainColumns,
    } = props;

    const page = rawPage || 1;
    const pageSize = rawPageSize || 10;
    const total = rawTotal || 0;
    const hasFooter = rawHasFooter === undefined ? true : rawHasFooter;
    const hasHeader = rawHasHeader === undefined ? true : rawHasHeader;

    const onFilterChange: FilterChangeHandler = (accessor, type, values) => {
        if (handleFilterChange) {
            handleFilterChange(accessor, type, values);
        }
    };

    const onFilterSubmit = () => { applyIfDefined(handleFilterSubmit); };

    const onFilterClear = () => { applyIfDefined(handleFilterClear); };

    const onHighlightRow = (id: number) => {
        if (rowHighlight && handleHighlightRow) {
            handleHighlightRow(id);
        }
    };

    const renderColumnHeader = (column: Column<RowT>, isLast: boolean) => {
        const {accessor, header, orderable} = column;
        const colSpan = isLast ? 2 : 1;

        if (!orderable) {
            return (
                <th
                    scope="col"
                    key={accessor}
                    className={`text-lighter${styles.th}`}
                    colSpan={colSpan}
                >
                    {header}
                </th>
            );
        }

        let styleName = '';
        let orderDir: OrderDir | undefined;

        if (column.accessor === orderBy) {
            styleName = orderDesc ? 'ordered-desc' : 'ordered-asc';
            orderDir = orderDesc ? 'desc' : 'asc';
        }

        const onOrderChange = () => {
            if (handleOrderChange) { handleOrderChange(column.accessor); }
        };

        return (
            <th
                scope="col"
                key={accessor}
                className={`border-secondary ${styles.th} ${styles.sortable} ${styles[styleName]}`}
                colSpan={colSpan}
                onClick={onOrderChange}
            >
                {header}
                <span
                    className={styles['order-caret']}
                    style={orderDir === 'desc' ? {opacity: 1} : {}}
                >
                    ▼
                </span>
                <span
                    className={styles['order-caret']}
                    style={orderDir === 'asc' ? {opacity: 1} : {}}
                >
                    ▲
                </span>
            </th>
        );
    };

    const genStyleNameOfRow = (row: Row<unknown>): string => flatten([
        highlightedRow === row.id ? ['highlighted-row'] : [],
        row.exportedByAssociation ? ['exported-by-association-row'] : [],
        row.selected ? ['selected-row'] : [],
    ]).map((x) => styles[x]).join(' ');

    const headers = columns
        .map((column, index) => renderColumnHeader(column, index === columns.length - 1));

    const onPageChange = (id: number) => {
        if (handlePageChange) { handlePageChange(id); }
    };

    return (
        <div
            className={`table-responsive ${styles['table-custom']}
            ${twoMainColumns ? styles['two-main-columns'] : ''}`}
        >
            <table className={`table ${styles.table}`}>
                {hasHeader
                 && (
                     <thead>
                         <tr className="">
                             {headers}
                         </tr>

                         {filter &&
                          <Filters
                              columns={columns}
                              filter={filter}
                              onChange={onFilterChange}
                              onSubmit={onFilterSubmit}
                              onClear={onFilterClear}
                          />
                         }
                     </thead>
                 )
                }


                {children && children}

                {!children &&
                 <tbody>
                     {(!rows || rows.length === 0) &&
                      <tr className={styles['empty-row']}>
                          <td>
                              {emptyMessage}
                          </td>
                      </tr>
                     }

                     {rows.map((row: Row<RowT>) => (
                         <tr
                             key={row.id}
                             onClick={() => onHighlightRow(row.id)}
                             className={genStyleNameOfRow(row)}
                         >
                             {columns.map((column, index) => (
                                 <td
                                     key={`${column.accessor}-${row.id}`}
                                     colSpan={index === columns.length - 1 ? 2 : 1}
                                 >
                                     {row[column.accessor] ? row[column.accessor] : ''}
                                 </td>
                             ))
                             }
                         </tr>
                     ))
                     }
                 </tbody>
                }

                {hasFooter &&
                 <tfoot className={styles.tfoot}>
                     <tr className="bg-secondary">
                         <td
                             colSpan={columns.length + 1}
                             className="text-center"
                         >
                             {pageSize < total &&
                              <Pagination
                                  currentPage={page}
                                  totalPages={Math.ceil(total / pageSize)}
                                  pagePadding={4}
                                  className="justify-content-center pagination-sm"
                                  onPageChange={onPageChange}
                              />
                             }
                         </td>
                     </tr>
                 </tfoot>
                }
            </table>
        </div>
    );
};

Table.defaultProps = defaultProps;

export default Table;
