import {History} from 'history';
import {flow} from 'lodash/fp';
import * as React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Dispatch} from 'redux';
import {logout} from '../actions';
import AuthStore from '../AuthStore';

interface Props {
    history: History;
    handleResetUser(): void;
}

class Logout extends Component<Props> {
    componentWillMount(): void {
        const {history, handleResetUser} = this.props;
        AuthStore.delete();
        handleResetUser();
        history.push('/login');
    }

    render(): ReactNode {
        return (
            <div className="d-flex justify-content-center mt-5 pt-5">
                Odhlašuji...
            </div>
        );
    }
}

const mapStateToProps = (): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleResetUser: () => dispatch(logout()),
});

export default flow([
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
])(Logout) as typeof Logout;
