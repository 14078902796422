import {LayoutAction} from './actions';

export interface LayoutState {
    menuOpen: boolean;
}

export const initialLayoutState: LayoutState = {
    menuOpen: false,
};

const reducer = (state: LayoutState | void = initialLayoutState, action: LayoutAction) => {
    switch (action.type) {
        case 'layout/TOGGLE_MENU': {
            const menuOpen = state ? !state.menuOpen : false;
            return {...state, menuOpen};
        }

        default:
            return state;
    }
};

export default reducer;
