import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Option as SelectOption} from 'react-select';
import {Field} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms} from 'forms';
import {PropsForForm} from '../../../forms/components/withForm';
import {RatingType} from '../../../types/RatingType';
import {validate} from './validations';

export interface NewStableFormValues {
    breedingId: string;
    stableName: string;
    companyName: string;
    ratingType: SelectOption<RatingType>;
}

interface OwnProps {}

type Props = PropsForForm<NewStableFormValues, OwnProps>;

class NewStableForm extends Component<Props> {
    static ratingTypeOptions: SelectOption<RatingType> = [
        {
            label: 'P',
            value: 'P',
        },
        {
            label: 'A',
            value: 'A',
        },
    ];

    render(): ReactNode {
        const {FormField, valid, handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12">
                        <Field
                            name="breedingId"
                            type="text"
                            component={FormField}
                            label="Číslo chovu"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Field
                            name="stableName"
                            type="text"
                            component={FormField}
                            label="Jméno stáje"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Field
                            name="companyName"
                            type="text"
                            component={FormField}
                            label="Jméno společnosti"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Field
                            name="ratingType"
                            type="select"
                            component={FormField}
                            label="Typ hodnocení"
                            options={NewStableForm.ratingTypeOptions}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        <Buttons.Button
                            importance="primary"
                            disabled={!valid}
                            type="submit"
                        >
                            Vytvořit
                        </Buttons.Button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Forms.withForm<NewStableFormValues, OwnProps, Props>(NewStableForm, {
    form: 'newStable',
    validate,
});
