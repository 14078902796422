import * as t from 'io-ts';

import BaseApi, {CResponse} from 'utils/BaseApi';
import {ActivePlan, ActivePlanSchema} from '../types/ActivePlan';
import {ArrayBufferSchema} from '../types/ArrayBufferType';
import {EmptyObject, EmptyObjectSchema} from '../types/EmptyObject';
import {FormDataSchema} from '../types/FormDataType';
import {ImportedPlan, ImportedPlanSchema} from '../types/ImportedPlan';
import {NewStable, NewStableSchema} from '../types/NewStable';
import {User, UserSchema} from '../types/User';

class Api extends BaseApi {
    getActivePlans(): Promise<CResponse<ActivePlan[]>> {
        return super._makeRequest({
            url: '/plans',
            requestSchema: t.void,
            responseSchema: t.array(ActivePlanSchema),
        });
    }

    uploadPlan(plan: File): Promise<CResponse<ImportedPlan>> {
        const data = new FormData();
        data.append('file', plan);
        return super._makeRequest({
            url: '/plans/import',
            method: 'post',
            data,
            requestSchema: FormDataSchema,
            responseSchema: ImportedPlanSchema,
        });
    }

    export(urlWithFilter: string): Promise<CResponse<ArrayBuffer>> {
        return super._makeRequest({
            url: urlWithFilter,
            responseType: 'arraybuffer',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseConvertNullToUndefined: false,
        });
    }

    createNewStable(stable: NewStable): Promise<CResponse<EmptyObject>> {
        return super._makeRequest({
            url: '/stables',
            method: 'post',
            data: stable,
            requestSchema: NewStableSchema,
            responseSchema: EmptyObjectSchema,
        });
    }

    getUsers(): Promise<CResponse<User[]>> {
        return super._makeRequest({
            url: '/users',
            requestSchema: t.void,
            responseSchema: t.array(UserSchema),
        });
    }
}

export default new Api();
