import {routerActions} from 'react-router-redux';
import {reset, startSubmit, stopSubmit} from 'redux-form';
import {SagaIterator} from 'redux-saga';
import {
    call, put, select, takeLatest,
} from 'redux-saga/effects';

import {CResponse} from 'utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {StoreState} from '../../app/types/StoreState';
import {convertErrorFromBeToFormError} from '../../notifications/utils/convertErrorFromBeToFormError';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {JwtToken} from '../../types/JwtToken';
import {UsernamePassword} from '../../types/UsernamePassword';
import {loginError, loginSuccess} from '../actions';
import Api from '../api';
import AuthStore from '../AuthStore';

function* execute(): SagaIterator {
    const formName = 'login';
    yield put(startSubmit(formName));
    const inputData: UsernamePassword = yield select((x: StoreState) => x.form.login.values);
    const response: CResponse<JwtToken> = yield call(Api.login, inputData);
    const {status, data} = response;

    if (status !== 200) {
        const err = data as ErrorFromBE;
        handleResponseError(response, 'Přihlášení', { isLogin: true });
        yield put(loginError(err));
        return yield put(stopSubmit(formName, convertErrorFromBeToFormError(err)));
    }

    const validData = data as JwtToken;
    if (!validData.token) { throw new Error('Token is missing in login response.'); }
    AuthStore.set(`Bearer ${validData.token}`);
    yield put(loginSuccess());
    yield put(stopSubmit(formName));
    yield put(routerActions.push('/'));
    return yield put(reset(formName));
}

function* loginSaga(): SagaIterator {
    yield takeLatest('user/LOGIN', execute);
}

export default loginSaga;
