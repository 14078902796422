import {call, put, takeLatest} from 'redux-saga/effects';

import {SagaIterator} from 'redux-saga';
import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {User} from '../../types/User';
import {getUsersError, getUsersSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const response: CResponse<User[]> = yield call(Api.getUsers);
    const {status, data} = response;

    if (status !== 200) {
        const err = data as ErrorFromBE;
        handleResponseError(response, 'Načtení seznamu uživatelů');
        return yield put(getUsersError(err));
    }

    return yield put(getUsersSuccess(data as User[]));
}

function* getUsersSaga(): SagaIterator {
    yield takeLatest('home/GET_USERS', execute);
}

export default getUsersSaga;
