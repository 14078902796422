import * as t from 'io-ts';
import {JwtToken, JwtTokenSchema} from 'types/JwtToken';
import {User, UserSchema} from 'types/User';
import {UsernamePassword, UsernamePasswordSchema} from 'types/UsernamePassword';
import BaseApi, {CResponse} from 'utils/BaseApi';

class Api extends BaseApi {
    loadCurrent(): Promise<CResponse<User>> {
        return super._makeRequest({
            url: '/user',
            requestSchema: t.void,
            responseSchema: UserSchema,
        });
    }

    login(data: UsernamePassword): Promise<CResponse<JwtToken>> {
        return super._makeRequest({
            url: '/account/login',
            method: 'post',
            data,
            requestSchema: UsernamePasswordSchema,
            responseSchema: JwtTokenSchema,
        });
    }
}

export default new Api();
