import {SagaIterator} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';

import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {ActivePlan} from '../../types/ActivePlan';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {getActivePlansError, getActivePlansSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const response: CResponse<ActivePlan[]> = yield call(Api.getActivePlans);
    const {status, data} = response;

    if (status !== 200) {
        const err = data as ErrorFromBE;
        handleResponseError(response, 'Načtení aktivních plánů');
        return yield put(getActivePlansError(err));
    }

    return yield put(getActivePlansSuccess(data as ActivePlan[]));
}

function* getActivePlansSagaIterator(): SagaIterator {
    yield takeLatest('home/GET_ACTIVE_PLANS', execute);
}

export default getActivePlansSagaIterator;
