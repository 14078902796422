// @flow strict-local

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import Main from './modules/app/components/Main/index';

const renderApplication = () => {
    const elem = document.getElementById('app');
    if (!elem) { throw new Error('app element not found'); }
    ReactDOM.render(
        <AppContainer>
            <div>
                <Main />
            </div>
        </AppContainer>,
        elem
    );
};

renderApplication();

if (module.hot) {
    module.hot.accept('./modules/app/components/Main/index', () => {
        renderApplication();
    });
}
