import {call, put, takeLatest} from 'redux-saga/effects';

import {SagaIterator} from 'redux-saga';
import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {User} from '../../types/User';
import {loadCurrentError, loadCurrentSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const response: CResponse<User> = yield call(Api.loadCurrent);
    const {status, data} = response;

    if (status !== 200) {
        const err = data as ErrorFromBE;
        if (status !== 401) { handleResponseError(response, 'Načtení uživatele'); }
        return yield put(loadCurrentError(err));
    }

    return yield put(loadCurrentSuccess(data as User));
}

function* loadCurrentSaga(): SagaIterator {
    yield takeLatest('user/LOAD_CURRENT', execute);
}

export default loadCurrentSaga;
