import * as React from 'react';
import {Component, ReactNode} from 'react';

import {Components as Icons} from 'icons';

import styles from './styles.sass';

interface Props {
    onImage: string;
    offImage: string;
    checked?: boolean;
    onTitle: string;
    offTitle: string;

    onChange(_: boolean): void;
}

interface State {
    checked?: boolean;
    image: string;
    title: string;
}

class ImageCheckbox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const {
            checked, onImage, offImage, onTitle, offTitle,
        } = this.props;
        const image = checked ? onImage : offImage;
        const title = checked ? onTitle : offTitle;

        this.state = {
            checked,
            image,
            title,
        };
    }

    handleChange = () => {
        const {
            onImage, offImage, onTitle, offTitle, onChange,
        } = this.props;
        const {checked} = this.state;

        const newState: State = {
            checked: !checked,
            image: !checked ? onImage : offImage,
            title: !checked ? onTitle : offTitle,
        };

        this.setState(newState);
        onChange(Boolean(newState.checked));
    }

    render(): ReactNode {
        const {checked, image, title} = this.state;

        return (
            <div className={styles['image-checkbox']} title={title}>
                <Icons.SvgIcon icon={image} />
                <input
                    className={styles.input}
                    type="checkbox"
                    checked={Boolean(checked)}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default ImageCheckbox;
