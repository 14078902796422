import {flow, get} from 'lodash/fp';
import * as React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from '../../app/types/StoreState';
import {ActivePlan} from '../../types/ActivePlan';
import {User} from '../../types/User';
import withUser from '../../user/components/withUser';
import {
    createNewStableAction, CreateNewStableActionPayload,
    exportAction,
    ExportActionPayload,
    getActivePlans,
    getUsers,
    uploadPlan, UploadPlanActionPayload,
} from '../actions';
import {ActivePlansPart} from '../components';
import {ExportFormValues} from '../types/ExportFormValues';

interface Props {
    activePlans?: ActivePlan[];
    isRoleAdmin: boolean;
    exportValues?: ExportFormValues;
    users?: User[];
    currentUser: User | null;

    handleUploadPlan(_: UploadPlanActionPayload): void;

    loadActivePlans(): void;

    loadUsers(): void;

    handleExport(args: ExportActionPayload): void;

    handleCreateNewStable(_: CreateNewStableActionPayload): void;
}

class Plans extends Component<Props> {
    render(): ReactNode {
        const {
            loadActivePlans,
            activePlans,
            handleUploadPlan,
            isRoleAdmin,
            handleExport,
            exportValues,
            handleCreateNewStable,
            users,
            loadUsers,
            currentUser,
        } = this.props;
        return (
            <div className="container mt-5 pt-4">
                {currentUser &&
                 <ActivePlansPart
                     loadActivePlans={loadActivePlans}
                     activePlans={activePlans}
                     isRoleAdmin={isRoleAdmin}
                     handleExport={handleExport}
                     exportValues={exportValues}
                     handleUploadPlan={handleUploadPlan}
                     handleCreateNewStable={handleCreateNewStable}
                     users={users}
                     loadUsers={loadUsers}
                     currentUser={currentUser}
                 />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    activePlans: state.plans.activePlans,
    exportValues: get('form.export.values', state) as ExportFormValues,
    users: state.plans.users,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    loadActivePlans: () => dispatch(getActivePlans()),
    handleUploadPlan: ({plan}: { plan: File }) => dispatch(uploadPlan({plan})),
    handleExport: (args: ExportActionPayload) => dispatch(exportAction(args)),
    handleCreateNewStable:
        (args: CreateNewStableActionPayload) => dispatch(createNewStableAction(args)),
    loadUsers: () => dispatch(getUsers()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Plans) as typeof Plans;
