import * as t from 'io-ts';
import {failure} from 'io-ts/lib/PathReporter';

export const validateSchema = <A, O>(type: t.Type<A, O>) => (value: t.mixed, onError: 'crash' | 'error' = 'crash'): A =>
    type.decode(value).getOrElseL((errors) => {
        console.log(value); // tslint:disable-line: no-console
        const errorString = failure(errors).join('\n');
        if (onError === 'crash') {
            throw new Error(errorString);
        } else {
            console.error(errorString); // tslint:disable-line: no-console
            return value as A;
        }
    });
