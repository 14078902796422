import * as React from 'react';

import {Components as Icons} from 'icons';
import {Column} from '../../types/Column';
import {Filter} from '../../types/Filter';
import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import SimpleFilter from '../SimpleFilter';

import filterSvg from './filter.svg';
import xCircleSvg from './xCircle.svg';

import styles from './styles.sass';

interface Props<RowT> {
    /** Array of table columns. */
    columns: Column<RowT>[];
    /** Array of active filters. */
    filter: Filter;
    /** Callback invoked when filter's input changes. */
    onChange: FilterChangeHandler;

    /** Callback invoked when filters are submitted. */
    onSubmit(): void;

    /** Callback invoked when filters are cleared. */
    onClear(): void;
}

/**
 * Iterate over table column objects and render appropriate filter type for filterable columns.
 */
const Filters = <RowT extends object>(props: Props<RowT>) => {
    const {
        columns,
        filter,
        onChange,
        onSubmit,
        onClear,
    } = props;

    const isAnyFilterActive = () => Object.keys(filter).length > 0;

    return (
        <tr className={styles.filter}>
            {
                columns.map((column) => {
                    const {accessor, filterable, filterType} = column;
                    const currentFilter = filter[accessor];

                    return (
                        <td
                            key={accessor}
                            className={styles.td}
                        >
                            {filterable && filterType === 'equals'
                             && (
                                 <SimpleFilter
                                     filter={currentFilter}
                                     accessor={accessor}
                                     onChange={onChange}
                                 />
                             )
                            }

                            {filterable && filterType === 'betweenDates' && null
                                // {
                                //     <DateRangeFilter
                                //         filter={currentFilter}
                                //         accessor={accessor}
                                //         onChange={onChange}
                                //     />
                                // }
                            }
                        </td>
                    );
                })
            }

            <td
                className={`d-flex justify-content-end align-items-center border-0 ${styles.td}`}
            >
                <button
                    className="btn btn-outline-primary btn-sm d-flex mr-1"
                    title="Filter data"
                    onClick={onSubmit}
                    type="submit"
                >
                    <Icons.SvgIcon icon={filterSvg} />
                    <b className="ml-1">
                        Filtrovat
                    </b>
                </button>

                {isAnyFilterActive() &&
                 <button
                     className={`btn-basic ${styles['clear-button']}`}
                     onClick={onClear}
                     title="Clear filters"
                     type="submit"
                 >
                     <Icons.SvgIcon icon={xCircleSvg} />
                 </button>
                }
            </td>
        </tr>
    );
};

export default Filters;
