import * as t from 'io-ts';

const optionalPart = t.partial({
    stack: t.string,
    field: t.string,
});
const requiredPart = t.interface({
    message: t.string,
});

export const ValidationErrorFromBeSchema = t.exact(t.intersection([
    optionalPart,
    requiredPart,
]));

export interface ValidationErrorFromBe extends t.TypeOf<typeof ValidationErrorFromBeSchema> {}

export const ErrorFromBESchema = t.array(ValidationErrorFromBeSchema);

export type ErrorFromBE = ValidationErrorFromBe[];
