import {isArray} from 'lodash/fp';

import {ErrorFromBE} from '../../types/ErrorFromBE';

interface StringMap {[_: string]: string;}

const emptyFormError: StringMap = {};

export const convertErrorFromBeToFormError = (beErrs: ErrorFromBE): StringMap => {
    if (!isArray(beErrs)) {
        //  tslint:disable-next-line: no-console
        console.warn('BE error is not an array.');
        return {};
    }
    return beErrs.reduce(
        (acc, err): StringMap => (err.field ? {
            ...acc,
            [err.field]: err.message,
        } : acc), emptyFormError,
    );
};
