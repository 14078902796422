import * as React from 'react';
import {Component, ReactNode} from 'react';

import classNames from 'utils/classNames';
import {stringTuple} from '../../../../utils/stringTuple';
import {BaseFieldType} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';

import styles from './styles.sass';

export type InputFieldType<ActionParam> = {
    type: InputFieldValidType;
    input: {
        value?: string;
        name?: string;
    };
} & BaseFieldType<ActionParam>;

export const inputFieldValidTypes = stringTuple('text', 'email', 'tel', 'hidden', 'number', 'password');
export type InputFieldValidType = typeof inputFieldValidTypes[number];

type Props<ActionParam> = {
    field: InputFieldType<ActionParam>;
} & ChildProps;

class Input<ActionParam> extends Component<Props<ActionParam>> {
    render(): ReactNode {
        const {className: classNameRaw, field} = this.props;
        const className = classNameRaw || '';
        if (!inputFieldValidTypes.includes(field.type)) {
            throw new Error(`invalid type = ${field.type}`);
        }
        const {
            label,
            helpText,
            input,
            meta,
            disabled,
            type,
        } = field;
        const {asyncValidating, submitting} = meta;
        const nonEmpty = Boolean(input && input.value);
        const classes = classNames('form-group', 'w-100', 'position-relative', className);

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>
                <div className={`${styles['helper-pt15']}`} />
                <input
                    className={`form-control form-custom ${nonEmpty ? styles['non-empty'] : ''}`}
                    {...input}
                    type={type}
                    id={input.name}
                    disabled={asyncValidating || submitting || disabled}
                />
                <div className={`${styles['animated-underline']}`} />
                <div className={styles['form-placeholder']}>
                    {label}
                </div>
            </div>
        );
    }
}

export default Input;
