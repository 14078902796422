import * as React from 'react';

import {stringTuple} from 'utils/stringTuple';
import {BaseFieldType} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

import styles from './styles.sass';

export type CheckboxFieldType<ActionParam> = {
    type: CheckBoxFieldValidType;
    input: {
        name: string;
    };
} & BaseFieldType<ActionParam>;

export const checkBoxFieldValidTypes = stringTuple('checkbox');
export type CheckBoxFieldValidType = typeof checkBoxFieldValidTypes[number];

type Props<ActionParam> = {
    field: CheckboxFieldType<ActionParam>;
} & ChildProps;

const Checkbox = (props: Props<unknown>) => {
    const {field} = props;

    const {
        label,
        helpText,
        input,
        meta,
        disabled,
    } = field;

    const {asyncValidating, submitting} = meta;

    return (
        <div>
            <HelpText>
                {helpText}
            </HelpText>
            <div className={`${styles['my-checkbox']}`}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={input.name}
                    disabled={asyncValidating || submitting || disabled}
                />

                <Label htmlFor={input.name} className="form-check-label">
                    .
                </Label>
                <div className={`${styles['custom-label']}`}>
                    {label}
                </div>
            </div>
        </div>
    );
};

export default Checkbox;
