import * as React from 'react';

import { Components as Buttons } from 'buttons';
import { Components as Icons } from 'icons';

import styles from './styles.sass';

interface Props {
    icon: string;
    className: string;
    disabled: boolean;
    type: 'button' | 'submit' | 'reset';
    onClick(): void;
}

const defaultProps = {
    className: '',
    type: 'button',
    disabled: false,
    onClick: null,
};

const IconButton = (props: Props) => {
    const {
        icon,
        className,
        type,
        disabled,
        onClick,
    } = props;

    return (
        <Buttons.Button
            className={`${className} ${styles['icon-button']}`}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            <Icons.SvgIcon icon={icon} />
        </Buttons.Button>
    );
};
IconButton.defaultProps = defaultProps;

export default IconButton;
