import * as React from 'react';
import {ReactNode} from 'react';
import {CookiesProvider} from 'react-cookie';
import {hot} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';

import {Components as Layout} from 'layout';
import Notifications from 'notifications/components/Notifications';
import {Routes as PlansRoutes} from 'plans';
import {Routes as UserRoutes} from 'user';
import {history, store} from '../../store';

import 'styles/styles.sass';

class Main extends React.Component<void> {
    render(): ReactNode {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <CookiesProvider>
                        <div className="pb-4 pt-2 position-relative">
                            <Notifications />
                            <Layout.MainMenu />
                            <Layout.Header />

                            <PlansRoutes />
                            <UserRoutes />
                        </div>
                    </CookiesProvider>
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default hot(module)(Main);
