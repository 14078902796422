import {flow, noop} from 'lodash/fp';
import * as React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from '../../../app/types/StoreState';
import {User} from '../../../types/User';
import {loadCurrent} from '../../actions';

interface Props {
    currentUser: User | null;
    loadingUser: boolean;
    isRoleBoniter: boolean;
    isRoleAdmin: boolean;

    handleLoadCurrentUser(): void;
}

const withUser = (WrappedComponent: React.ComponentType) => {
    class WithUser extends Component<Props> {
        static defaultProps: Props = {
            currentUser: null,
            loadingUser: false,
            isRoleBoniter: false,
            isRoleAdmin: false,

            handleLoadCurrentUser: noop,
        };

        componentWillMount(): void {
            const {currentUser, loadingUser, handleLoadCurrentUser} = this.props;
            if (!currentUser && !loadingUser) { handleLoadCurrentUser(); }
        }

        render(): ReactNode {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state: StoreState): Partial<Props> => {
        const rolename = state.user.current ? state.user.current.rolename : undefined;
        return {
            currentUser: state.user.current,
            loadingUser: state.user.loading,
            isRoleBoniter: rolename === 'ROLE_BONITER',
            isRoleAdmin: rolename === 'ROLE_ADMIN',
        };
    };

    const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
        handleLoadCurrentUser: () => dispatch(loadCurrent()),
    });

    return flow([
        connect(mapStateToProps, mapDispatchToProps),
    ])(WithUser);
};

export default withUser;
