import {Component, ReactNode} from 'react';
import * as React from 'react';
import {Field} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms} from 'forms';
import {Option as SelectOption} from 'react-select';
import {User} from 'types/User';
import {PropsForForm} from '../../../forms/components/withForm';
import {ExportFormUserItem} from '../../types/ExportFormUserItem';
import {ExportFormValues} from '../../types/ExportFormValues';
import {ExportFormOptionsGeneration, MonthYear} from './ExportFormOptionsGeneration';
import {validate} from './validations';

interface OwnProps {
    adminMode: boolean;
    users?: User[];
    currentUser: User;

    handleExport(): void;

    handleExportTesting(): void;

    handleExportDeadAnimals(): void;
}

export type ExportFormProps = PropsForForm<ExportFormValues, OwnProps>;

class ExportForm extends Component<ExportFormProps> {
    render(): ReactNode {
        const {
            handleExport,
            handleExportTesting,
            handleExportDeadAnimals,
            valid,
            adminMode,
            users,
            FormField,
            currentUser,
        } = this.props;
        const now = new Date();
        const stopDate: MonthYear = {month: now.getMonth() + 1, year: now.getFullYear()};
        const startDate = {month: 7, year: 2018};
        const exportOptions = ExportFormOptionsGeneration.genExportOptions(startDate, stopDate);
        const userOptions: SelectOption<ExportFormUserItem>[] = !users ? [] : [
            {
                label: 'Všichni bonitéři',
                value: {type: 'all'},
            },
            {
                label: `Já (${currentUser.firstname} ${currentUser.lastname})`,
                value: {
                    type: 'user',
                    user: currentUser,
                },
            },
            ...users.filter((x) => x.id !== currentUser.id).map((u): SelectOption<ExportFormUserItem> => ({
                label: `${u.firstname} ${u.lastname}`,
                value: {type: 'user', user: u},
            })),
        ];
        return (
            <form>
                <div className="row">
                    <div className="col-12">
                        <Field
                            name="month"
                            component={FormField}
                            type="select"
                            label="Měsíc"
                            options={exportOptions}
                        />
                    </div>
                </div>
                {adminMode &&
                 <Field
                     name="user"
                     type="select"
                     component={FormField}
                     label="Uživatel(é)"
                     options={userOptions}
                 />
                }
                <div className="mb-2" />
                <div className="d-flex flex-column">
                    <Buttons.Button
                        importance="primary"
                        disabled={!valid}
                        onClick={handleExport}
                        type="button"
                    >
                        Hodnocená zvířata
                    </Buttons.Button>
                </div>
                <div className="m-2" />
                <div className="d-flex flex-column flex-md-row">
                    <Buttons.Button
                        importance="secondary"
                        disabled={!valid}
                        onClick={handleExportTesting}
                        type="button"
                        className="flex-grow-1"
                    >
                        Sezpívání
                    </Buttons.Button>
                    <div className="m-2" />
                    <Buttons.Button
                        importance="secondary"
                        disabled={!valid}
                        onClick={handleExportDeadAnimals}
                        type="button"
                        className="flex-grow-1"
                    >
                        Mrtvá zvířata
                    </Buttons.Button>
                </div>
            </form>
        );
    }
}

export default Forms.withForm<ExportFormValues, OwnProps, ExportFormProps>(ExportForm, {
    form: 'export',
    validate,
});
