import {User} from '../types/User';
import {UserAction} from './actions';

export interface UserState {
    current: User | null;
    loading: boolean;
}

export const initialUserState: UserState = {
    current: null,
    loading: false,
};

const reducer = (state: UserState | void = initialUserState, action: UserAction) => {
    switch (action.type) {
        case 'user/LOAD_CURRENT': {
            return { ...state, loading: true };
        }

        case 'user/LOAD_CURRENT_SUCCESS': {
            return { ...state, current: action.payload, loading: false };
        }

        case 'user/LOGOUT': {
            return { ...state, current: null };
        }

        default:
            return state;
    }
};

export default reducer;
