import {Column} from '../types/Column';

/**
 * Pick columns from array. Useful mostly for conditional rendering of different columns. Eg.
 * depending on current user role.
 *
 * @param {Object[]} columns - Array of objects representing table columns.
 * @param {string} columnsToPick - One or more columns to pick.
 *
 * @return {Object[]} - Array of table columns.
 */
const pickColumns = <RowT>(columns: Column<RowT>[], ...columnsToPick: (keyof RowT & string)[]) => (
    columns.filter((column) => columnsToPick.includes(column.accessor))
);

export default pickColumns;
