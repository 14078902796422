import * as React from 'react';
import {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import classNames from 'utils/classNames';
import {BaseFieldType, FieldType} from '../../types/BaseFieldType';
import Checkbox, {CheckboxFieldType, checkBoxFieldValidTypes} from '../Checkbox';
import Input, {InputFieldType, inputFieldValidTypes} from '../Input';
import Radio, {RadioFieldType, radioFieldValidTypes} from '../Radio';
import Select, {SelectFieldType, selectFieldValidTypes} from '../Select';
import Textarea, {TextareaFieldType, textareaFieldValidTypes} from '../Textarea';

import checkSvg from './check.svg';
import searchSvg from './search.svg';
import spinnerSvg from './spinner.svg';
import warningSvg from './warning.svg';

import styles from './styles.sass';

const isInTypesArray = <T extends FieldType>(arr: T[], x: FieldType): x is T => (arr as FieldType[]).includes(x);

type Props<ActionParam, SelectOptionT> =
    BaseFieldType<ActionParam>
    & (
    SelectFieldType<ActionParam, SelectOptionT>
    | InputFieldType<ActionParam>
    | CheckboxFieldType<ActionParam>
    | TextareaFieldType<ActionParam>
    | RadioFieldType<ActionParam>
    );

const genStyleNameAndFeedbackIcon = (
    asyncValidating: boolean,
    dirty: boolean,
    active: boolean,
    touched: boolean,
    error: unknown,
): [string, string | null] => {
    if (asyncValidating) {
        return ['is-async-validating', spinnerSvg];
    } else if (dirty && !active && touched) {
        if (error) {
            return ['is-invalid', warningSvg];
        } else {
            return ['is-valid', checkSvg];
        }
    }
    return ['', null];
};

const wrap = <ActionParams, SelectOptionT>(child: ReactNode, props: Props<ActionParams, SelectOptionT>): ReactNode => {
    const {
        meta: {
            touched,
            error,
            asyncValidating,
            active,
            dirty,
            valid,
        },
        type,
        className,
        wrapperClassName,
        action,
        input,
    } = props;
    const [styleName, feedbackIcon] =
        genStyleNameAndFeedbackIcon(asyncValidating, dirty, Boolean(active), touched, error);

    const wrapperClassNames = classNames(
        'field-component', 'position-relative', 'd-flex', 'align-items-center', wrapperClassName,
        ...['field', styleName].map((x) => styles[x]),
    );

    const onActionClick = () => {
        const val = input ? input.value : undefined;
        action(val);
    };

    return (
        <div className={wrapperClassNames}>
            {child}

            {
                action &&
                <Buttons.IconButton
                    icon={searchSvg}
                    className={`${String(className)} btn btn-warning ml-2 ${styles['action-button']}`}
                    type="button"
                    onClick={onActionClick}
                    disabled={!dirty || (dirty && !touched) || !valid || asyncValidating}
                />
            }

            {type !== 'checkbox' &&
             <span
                 className={`${String(className)} ml-2 ${styles['feedback-icon']}`}
                 dangerouslySetInnerHTML={{__html: feedbackIcon || ''}}
             />
            }

            <div
                className={`invalid-tooltip ${styles['invalid-tooltip']}`}
            >
                {touched && error}
            </div>
        </div>
    );
};

class Field<ActionParam, SelectOptionT> extends Component<Props<ActionParam, SelectOptionT>> {
    render(): ReactNode {
        const props = this.props;
        const {
            type,
            className,
        } = props;

        if (isInTypesArray(inputFieldValidTypes, type)) {
            return wrap((
                <Input
                    field={props as unknown as InputFieldType<ActionParam>}
                    className={className}
                />
            ), props);
        }
        if (isInTypesArray(selectFieldValidTypes, type)) {
            return wrap((
                <Select
                    field={props as unknown as SelectFieldType<ActionParam, unknown>}
                    className={className}
                />
            ), props);
        }
        if (isInTypesArray(checkBoxFieldValidTypes, type)) {
            return wrap((
                <Checkbox
                    field={props as unknown as CheckboxFieldType<ActionParam>}
                    className={className}
                />
            ), props);
        }
        if (isInTypesArray(textareaFieldValidTypes, type)) {
            return wrap((
                <Textarea
                    field={props as unknown as TextareaFieldType<ActionParam>}
                    className={className}
                />
            ), props);
        }
        if (isInTypesArray(radioFieldValidTypes, type)) {
            return wrap((
                <Radio
                    field={props as unknown as RadioFieldType<ActionParam>}
                    className={className}
                />
            ), props);
        }
        throw new Error('unknown type');
    }
}

export default Field;
