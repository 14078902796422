import * as React from 'react';
import {SyntheticEvent} from 'react';

import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {FilterPart} from '../../types/FilterPart';

import styles from './styles.sass';

interface Props {
    filter?: FilterPart;
    accessor: string;
    onChange: FilterChangeHandler;
}

const SimpleFilter = (props: Props) => {
    const { filter, accessor, onChange } = props;

    const inputOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
        onChange(accessor, 'equals', [e.currentTarget.value]);
    };

    return (
        <div className="d-flex align-items-center">
            <input
                className={`form-control form-control-sm ${styles.input}`}
                value={filter && filter.values ? filter.values[0] : ''}
                placeholder="Filtrovat..."
                onChange={inputOnChange}
            />
        </div>
    );
};

export default SimpleFilter;
