import * as t from 'io-ts';

export class ArrayBufferType extends t.Type<ArrayBuffer> {
    //  tslint:disable-next-line: variable-name
    readonly _tag: 'ArrayBufferType' = 'ArrayBufferType';

    constructor() {
        super(
            'ArrayBuffer',
            (m: t.mixed): m is ArrayBuffer => m instanceof ArrayBuffer,
            (m, c) => (this.is(m) ? t.success(m) : t.failure(m, c)),
            t.identity,
        );
    }
}

export const ArrayBufferSchema = new ArrayBufferType();
