import * as React from 'react';
import {Component} from 'react';
import {ReactNode} from 'react';
import {Route, Switch} from 'react-router-dom';

import * as Screens from './screens';

class Routes extends Component {
    render(): ReactNode {
        return (
            <Switch>
                <Route exact path="/login" component={Screens.Login} />
                <Route exact path="/logout" component={Screens.Logout} />
            </Switch>
        );
    }
}

export default Routes;
