import * as React from 'react';

import {ReactNode} from 'react';

import styles from './styles.sass';

type Importance = 'primary' | 'secondary';

interface Props {
    children?: ReactNode;
    type: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    className?: string;
    importance?: Importance;
    onClick?(): void;
}

const Button = (props: Props) => {
    const {
        children,
        onClick,
        type,
        disabled,
        className,
        importance,
    } = props;
    return (
        <button
            onClick={onClick}
            type={type}
            className={`${className || ''} ${importance && styles[importance]} ${disabled ? styles.disabled : ''}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
