import * as React from 'react';
import {Component, ReactNode} from 'react';

import {Components as Layout} from 'layout';
import {Components as Tables} from 'tables';
import formatDate from 'utils/formatDate';
import {Row} from '../../../tables/components/Table';
import {Column} from '../../../tables/types/Column';
import {ActivePlan} from '../../../types/ActivePlan';
import {NewStable} from '../../../types/NewStable';
import {User} from '../../../types/User';
import {CreateNewStableActionPayload, ExportActionPayload, UploadPlanActionPayload} from '../../actions';
import {ExportFormValues} from '../../types/ExportFormValues';
import {NewStableFormValues} from '../../types/NewStableFormValues';
import ExportForm from '../ExportForm';
import NewStableForm from '../NewStableForm';
import UploadPlanButton from '../UploadPlanButton';

interface Props {
    activePlans?: ActivePlan[];
    isRoleAdmin: boolean;
    exportValues?: ExportFormValues;
    users?: User[];
    currentUser: User;

    loadActivePlans(): void;

    loadUsers(): void;

    handleExport(args: ExportActionPayload): void;

    handleUploadPlan(args: UploadPlanActionPayload): void;

    handleCreateNewStable(args: CreateNewStableActionPayload): void;
}

class ActivePlansPart extends Component<Props> {
    static genData(activePlans: ActivePlan[]): Row<ActivePlan>[] {
        return activePlans.map((x, idx): Row<ActivePlan> => ({
            ...x,
            id: idx,
            updatedDate: x.updatedDate ? formatDate(x.updatedDate) : '-',
            importedDate: x.importedDate ? formatDate(x.importedDate) : '-',
        }));
    }

    static genColumns(): Column<ActivePlan>[] {
        return [
            {
                accessor: 'firstname',
                header: 'Jméno',
            },
            {
                accessor: 'lastname',
                header: 'Příjmení',
            },
            {
                accessor: 'updatedDate',
                header: 'Poslední změna',
            },
            {
                accessor: 'measuredAnimals',
                header: 'Změřeno',
            },
            {
                accessor: 'totalAnimals',
                header: 'Celkově zvířat',
            },
            {
                accessor: 'importedDate',
                header: 'Datum vytvoření',
            },
            {
                accessor: 'stablesCount',
                header: 'Počet stájí',
            },
        ];
    }

    static convertFormValuesToStable = (data: NewStableFormValues): NewStable => ({
        ...data,
        ratingType: data.ratingType ? (data.ratingType.value || undefined) : undefined,
    })

    componentWillMount(): void {
        const {loadActivePlans, loadUsers, isRoleAdmin} = this.props;
        loadActivePlans();
        if (isRoleAdmin) { loadUsers(); }
    }

    constructExportUrl = (urlPart: string): string => {
        const {exportValues, isRoleAdmin} = this.props;
        if (!exportValues || !exportValues.month || !exportValues.month.value) {
            throw new Error(`Missing form values. ${JSON.stringify(exportValues)}`);
        }
        if (isRoleAdmin && !exportValues.user) {
            throw new Error(`Missing user field for admin. ${JSON.stringify(exportValues)}`);
        }
        const {month, user} = exportValues;
        const all = user && user.value && user.value.type === 'all';
        const exportAllPart = '&exportAll=true';
        const userIdPart = user && user.value && user.value.type === 'user' ? `&userId=${user.value.user.id}` : '';
        const filterPart = all ? exportAllPart : userIdPart;
        const filter = `${month.value}${filterPart}`;
        return `${urlPart}?${filter}`;
    }

    handleExport = (urlPrefix: string) => {
        const {handleExport} = this.props;
        handleExport({urlWithFilter: this.constructExportUrl(urlPrefix)});
    }

    onExport = () => {
        this.handleExport('measurements/export');
    }

    onExportTesting = () => {
        this.handleExport('measurements/testing-export');
    }

    onExportDeadAnimals = () => {
        this.handleExport('ratings/export-dead');
    }

    onSubmitCreateNewStable = (data: NewStableFormValues) => {
        const {handleCreateNewStable} = this.props;
        handleCreateNewStable({
            stable: ActivePlansPart.convertFormValuesToStable(data),
        });
    }

    render(): ReactNode {
        const {
            activePlans,
            isRoleAdmin,
            handleUploadPlan,
            users,
            currentUser,
        } = this.props;

        const caption = (
            <div>
                Aktuální plány
                <UploadPlanButton handleUploadPlan={handleUploadPlan} />
            </div>
        );

        return (
            <Layout.MainPanel caption={caption}>
                {activePlans && (
                    <Tables.Table
                        columns={ActivePlansPart.genColumns()}
                        rows={ActivePlansPart.genData(activePlans)}
                        emptyMessage={(<div>No data.</div>)}
                        twoMainColumns
                    />
                )}
                <div className="mb-5" />
                <div className="row">
                    <div className="col-6">
                        <Layout.Panel caption="Export">
                            <ExportForm
                                handleExport={this.onExport}
                                handleExportTesting={this.onExportTesting}
                                handleExportDeadAnimals={this.onExportDeadAnimals}
                                adminMode={isRoleAdmin}
                                users={users}
                                currentUser={currentUser}
                            />
                        </Layout.Panel>
                    </div>
                    <div className="col-6">
                         <Layout.Panel caption="Nová stáj">
                             <NewStableForm
                                 onSubmit={this.onSubmitCreateNewStable}
                             />
                         </Layout.Panel>
                    </div>
                </div>
            </Layout.MainPanel>
        );
    }
}

export default ActivePlansPart;
