import {ActivePlan} from '../types/ActivePlan';
import {User} from '../types/User';
import {PlansAction} from './actions';

export interface HomeState {
    activePlans?: ActivePlan[];
    users?: User[];
}

const initialState: HomeState = {
    activePlans: undefined,
    users: undefined,
};

const reducer = (state: HomeState | void = initialState, action: PlansAction) => {
    switch (action.type) {
        case 'home/GET_ACTIVE_PLANS_SUCCESS': {
            return {
                ...state,
                activePlans: action.payload,
            };
        }

        case 'home/GET_USERS_SUCCESS': {
            return {
                ...state,
                users: action.payload,
            };
        }

        default:
            return state;
    }
};
export default reducer;
