import {flow} from 'lodash/fp';
import * as React from 'react';
import {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';

import {StoreState} from '../../../app/types/StoreState';
import {User} from '../../../types/User';

interface OuterProps {}

interface InnerProps {
    user: User | null;
}

type Props = InnerProps & OuterProps;

class Header extends Component<Props> {
    render(): ReactNode {
        const {user} = this.props;
        return (
            <header className="d-flex justify-content-end">
                {user &&
                 <span />
                }
            </header>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    user: state.user.current,
});
const mapDispatchToProps = (): Partial<Props> => ({});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Header) as ComponentClass<OuterProps>;
