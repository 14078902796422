import * as React from 'react';
import {Component} from 'react';
import {ReactNode} from 'react';
import ReduxToastr from 'react-redux-toastr';

import './styles.sass';

class Notifications extends Component {
    render(): ReactNode {
        return (
            <div id="notifications">
                <ReduxToastr
                    timeOut={0}
                    transitionIn="bounceInDown"
                    transitionOut="fadeOut"
                />
            </div>
        );
    }
}

export default Notifications;
