import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {Type} from 'io-ts';
import {get, isArray} from 'lodash/fp';

import {ErrorFromBE, ErrorFromBESchema, ValidationErrorFromBeSchema} from 'types/ErrorFromBE';
import AuthStore from 'user/AuthStore';
import {removeNullFields} from './removeNullFields';
import {validateSchema} from './validateSchema';

const BASE_URL = __API__;

export type CResponse<T> = AxiosResponse<T | ErrorFromBE>;

interface RequestArgs<TResp, TReq> {
    url: string;
    responseSchema: Type<TResp> | null;
    requestSchema: Type<TReq> | null;
    baseURL?: string;
    method?: string;
    headers?: { [_: string]: string };
    params?: object;
    data?: TReq;
    timeout?: number;
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
    responseConvertNullToUndefined?: boolean;
}

interface AxiosRequestConfigFixed extends AxiosRequestConfig {
    headers?: { [_: string]: string };
    params?: object;
    data?: unknown;
}

class BaseApi {
    // TODO: better types?
    private static processResponseData<TResp>(
        rawData: TResp,
        convertNullToUndefined: boolean = true,
    ): TResp {
        if (!convertNullToUndefined) { return rawData; }
        else if (isArray(rawData)) {
            return (rawData as unknown as object[]).map(removeNullFields) as unknown as TResp;
        } else {
            return removeNullFields(rawData as unknown as object) as unknown as TResp;
        }
    }

    async _makeRequest<TResp, TReq>(args: RequestArgs<TResp, TReq>): Promise<CResponse<TResp>> {
        const config: AxiosRequestConfigFixed = {
            url: args.url,
            baseURL: args.baseURL || BASE_URL,
            method: args.method || 'get',
            headers: args.headers || {},
        };
        if (!config.url) {
            throw new Error('Missing URL.');
        }
        if (!config.headers) {
            throw new Error('Missing headers.');
        }
        config.headers['Cache-Control'] = 'no-cache';
        if (args.params) {
            config.params = args.params;
        }
        if (args.timeout) {
            config.timeout = args.timeout;
        }
        if (args.data) {
            config.data = args.data;
        }
        if (args.responseType) {
            config.responseType = args.responseType;
        }
        if (args.url !== '/account/login') {
            config.headers.Authorization = AuthStore.get() || '';
        }
        if (args.requestSchema) {
            validateSchema(args.requestSchema)(config.data);
        } else {
            console.warn('Missing request schema: ', args); // tslint:disable-line: no-console
        }

        console.log('START Request:', config); // tslint:disable-line: no-console

        try {
            const response = await axios(config);
            const rawData = response.data;
            response.data =
                BaseApi.processResponseData(response.data, args.responseConvertNullToUndefined);
            console.log('FINISH!', 'response =', response, 'raw data =', rawData); // tslint:disable-line: no-console
            if (args.responseSchema) {
                validateSchema(args.responseSchema)(response.data);
            } else {
                console.warn('Missing response schema: ', args); // tslint:disable-line: no-console
            }
            return response;
        } catch (e) {
            const error: AxiosError = e;
            //  tslint:disable-next-line: no-console
            console.log('ERROR:', error, '\n', JSON.stringify(error, null, 2));
            // Redirect unauthorized user to login page
            if (get('response.status', error) === 401) {
                if (!config.url.endsWith('/account/login')) {
                    window.location.href = '/login';
                }
            }
            if (!error.response) {throw new Error('Error response is missing.'); }
            error.response.data = BaseApi.processResponseData(error.response.data, true);
            validateSchema(ErrorFromBESchema)(error.response.data, 'error');
            return error.response; // assuming there is always response
        }
    }
}

export default BaseApi;
