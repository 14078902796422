import {LoginFormValues} from '../../types/LoginFormValues';

type Errors = { [_ in keyof LoginFormValues]?: string };
export const validate = (values: LoginFormValues): Errors => {
    const errors: Errors = {};

    if (!values.username) {
        errors.username = 'Uživatelské jméno je povinná položka.';
    }
    if (!values.password) {
        errors.password = 'Heslo je povinná položka.';
    }

    return errors;
};
