import * as React from 'react';
import {ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    caption?: string | ReactNode;
    children?: ReactNode;
}

const MainPanel = (props: Props) => {
    const {caption, children} = props;
    return (
        <div className={styles.panel}>
            <h3>
                {caption}
            </h3>
            {children}
        </div>
    );
};

export default MainPanel;
