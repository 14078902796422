import fileDownload from 'js-file-download';
import {reset, startSubmit, stopSubmit} from 'redux-form';
import {call, put, takeLatest} from 'redux-saga/effects';

import {SagaIterator} from 'redux-saga';
import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {convertErrorFromBeToFormError} from '../../notifications/utils/convertErrorFromBeToFormError';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {ExportAction, exportError, exportSuccess} from '../actions';
import Api from '../api';

function* execute(action: ExportAction): SagaIterator {
    const {payload: {urlWithFilter}} = action;
    const response: CResponse<ArrayBuffer> = yield call(Api.export, urlWithFilter);
    const {status, data: rawData} = response;
    const formName = 'export';

    yield put(startSubmit(formName));

    if (status !== 200) {
        const err = rawData as ErrorFromBE;
        handleResponseError(response, 'Export');
        yield put(exportError(err));
        return yield put(stopSubmit(formName, convertErrorFromBeToFormError(err)));
    }

    const data = rawData as ArrayBuffer;
    const fileName: string = (response as { headers: { [_: string]: string } }).headers['x-suggested-filename'];
    fileDownload(data as unknown as string, fileName);
    yield put(exportSuccess(data));
    yield put(stopSubmit(formName));
    return yield put(reset(formName));
}

function* exportSaga(): SagaIterator {
    yield takeLatest('home/EXPORT', execute);
}

export default exportSaga;
