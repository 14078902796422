import * as t from 'io-ts';

import {UserRoleNameSchema} from './UserRoleName';

export const UserSchema = t.exact(t.interface({
    id: t.number,
    firstname: t.string,
    lastname: t.string,
    rolename: UserRoleNameSchema,
}));

export interface User extends t.TypeOf<typeof UserSchema> {}
