import {reducer as toastrReducer} from 'react-redux-toastr';
import {combineReducers, Reducer} from 'redux';
import {reducer as form} from 'redux-form';

import {reducer as layout} from 'layout';
import {reducer as plans} from 'plans';
import {reducer as user} from 'user';
import {StoreState} from './types/StoreState';

const reducers: { [_ in keyof StoreState]: Reducer } = {
    toastr: toastrReducer,
    form: form.plugin({}),

    plans,
    user,
    layout,
};
const reducer = combineReducers(reducers);

export default reducer;
