import { flow } from 'lodash/fp';
import * as React from 'react';
import {Component, ComponentClass, ReactNode} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Components as Icons } from 'icons';
import {Dispatch} from 'redux';
import {StoreState} from '../../../app/types/StoreState';
import {User} from '../../../types/User';
import { toggleMenu } from '../../actions';

import logoutSvg from './logout.svg';
import styles from './styles.sass';

interface OuterProps {}

interface InnerProps {
    user: User | null;
    open: boolean;
    handleToggle(): void;
}

type Props = InnerProps & OuterProps;

class MainMenu extends Component<Props> {
    render(): ReactNode {
        const { user, handleToggle, open } = this.props;
        return (
            <div
                className={`${styles['menu-container']} ${open ? styles['wrapper-open'] : styles['wrapper-closed']}`}
            >
                {user &&
                <div
                    className={`${styles.mainmenu} ${open ? styles.open : styles.closed}`}
                >
                    <div className={`${styles['slide-menu']}`}>
                        <Link
                            to="/"
                            href="/"
                        >
                            Správa plánů
                        </Link>
                        <Link
                            to="/"
                            href="/"
                        >
                            Správa plánů
                        </Link>
                        <Link
                            to="/"
                            href="/"
                        >
                            Správa plánů
                        </Link>
                        <Link
                            to="/"
                            href="/"
                        >
                            Správa plánů
                        </Link>
                        <span className={`${styles['logout-container']}`}>
                            <span className={`${styles['logout-wrapper']}`}>
                                <Icons.SvgIcon
                                    icon={logoutSvg}
                                    className={`${styles['logout-icon']}`}
                                />
                                <Link to="/logout">
                            Odhlásit
                                </Link>
                            </span>
                        </span>
                    </div>

                    <div className={`${styles['top-menu']}`}>
                        <button
                            type="button"
                            onClick={handleToggle}
                            className={styles.hamButton}
                        >
                            <span role="img" aria-label="Menu" />
                        </button>
                        <div className={`${styles['top-menu-right']}`}>
                            <h3>
                                Správa plánů - červení
                            </h3>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    user: state.user.current,
    open: state.layout.menuOpen,
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleToggle: () => dispatch(toggleMenu()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(MainMenu) as ComponentClass<OuterProps>;
