import Header from './Header';
import MainMenu from './MainMenu';
import MainPanel from './MainPanel';
import Panel from './Panel';

export {
    Header,
    MainMenu,
    MainPanel,
    Panel,
};
