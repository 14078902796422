import {flow, isEmpty, join} from 'lodash/fp';
import {call, put, takeLatest} from 'redux-saga/effects';

import showSuccess from 'notifications/utils/showSuccess';
import {SagaIterator} from 'redux-saga';
import {CResponse} from '../../../utils/BaseApi';
import {handleResponseError} from '../../../utils/handleResponseError';
import {ErrorFromBE} from '../../types/ErrorFromBE';
import {ImportedPlan} from '../../types/ImportedPlan';
import {getActivePlans, UploadPlanAction, uploadPlanError, uploadPlanSuccess} from '../actions';
import Api from '../api';

function* execute(action: UploadPlanAction): SagaIterator {
    const {payload: {plan}} = action;
    const response: CResponse<ImportedPlan> = yield call(Api.uploadPlan, plan);
    const {status, data} = response;
    const title = 'Nahrání nového plánu';

    if (status !== 200) {
        const err = data as ErrorFromBE;
        handleResponseError(response, title);
        return yield put(uploadPlanError(err));
    }

    const importedPlan: ImportedPlan = data as ImportedPlan;
    yield put(uploadPlanSuccess(importedPlan));
    const skippedIds = importedPlan.skippedBreedingIds;
    const skippedStablesPart: string = isEmpty(skippedIds) ? '' : flow([
        join(', '),
        (x: string) => ` Přeskočené chovy: ${x}.`,
    ])(skippedIds);
    showSuccess(title, `Plán zpracován.${skippedStablesPart}`);
    return yield put(getActivePlans());
}

function* uploadPlanSaga(): SagaIterator {
    yield takeLatest('home/UPLOAD_PLAN', execute);
}

export default uploadPlanSaga;
