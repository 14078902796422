const formatDateForBe = (date: Date) => {
  // => date.toISOString();
  // BE does not support "2019-02-28T23:00:00.000Z" format
  // it needs "2019-03-01T23:00:00.000" (hours don't matter)
  let year = date.getFullYear().toString();
  let month = (date.getMonth()+1).toString().length==2?(date.getMonth()+1).toString():"0"+(date.getMonth()+1);
  let day = date.getDate().toString().length==2?date.getDate().toString():"0"+date.getDate().toString();
  return year + "-" + month + "-" + day + "T00:00:00.000";
}

export default formatDateForBe;
