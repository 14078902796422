import {isArray} from 'lodash/fp';

import {CResponse} from 'utils/BaseApi';
import showError from '../modules/notifications/utils/showError';
import showRequestError from '../modules/notifications/utils/showRequestError';

export interface HandleResponseErrorOptions {
    isLogin?: boolean;
}

const handleCommonBeError = (resp: CResponse<unknown>, title: string) => {
    const {data} = resp as any;
    if (isArray(data)) {
        showRequestError(title, data);
    } else {
        try {
            const decoder = new TextDecoder();
            const errorString = decoder.decode(data);
            const error = JSON.parse(errorString);
            showRequestError(title, error);
        } catch (e) {
            //  tslint:disable-next-line: no-console
            console.error('Returned data in error response are not a JSON array and are not convertible from ' +
                          'ArrayBuffer (or encoded JSON has invalid format).', e, data);
        }
    }
};

export const handleResponseError =
    (resp: CResponse<any>, title: string, opts: HandleResponseErrorOptions = {}) => {
        switch (resp.status) {
            case 401:
            case 403: {
                if (opts.isLogin) {
                    showError(title, 'Neplatné uživatelské jméno nebo heslo.');
                } else {
                    showError(title, 'Chyba autentizace.');
                }
                break;
            }

            case 400:
            case 404:
            case 500: {
                handleCommonBeError(resp, title);
                break;
            }

            default: {
                showError(title, 'Nastala neznámá chyba.');
            }
        }
    };
