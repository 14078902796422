import * as React from 'react';
import {ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    caption?: string;
    children: ReactNode;
}

const Panel = (props: Props) => {
    const {caption, children} = props;
    return (
        <span>
            <h3>
                {caption}
            </h3>
            <div className={`w-100 ${styles.panel}`}>
                {children}
            </div>
        </span>
    );
};

export default Panel;
