import Cookies from 'universal-cookie';

const cookieName = 'auth';

class AuthStore {
    private readonly cookies: Cookies = new Cookies('cmsch-rating');

    get(): string | undefined {
        return this.cookies.get(cookieName);
    }

    set(value: string): void {
        this.cookies.set(cookieName, value);
    }

    delete(): void {
        this.cookies.remove(cookieName);
    }
}

export default new AuthStore();
