const unfoldr = <A, B>(f: (b: B) => [A, B] | null, zero: B): A[] => {
    const res: A[] = [];
    let next: [A, B] | null;
    let state: B = zero;
    next = f(state);
    while (next) {
        const [newItem, newState] = next;
        res.push(newItem);
        state = newState;
        next = f(state);
    }
    return res;
};

export default unfoldr;
