import * as React from 'react';
import {Component, ReactNode} from 'react';

import classNames from 'utils/classNames';
import {stringTuple} from 'utils/stringTuple';
import {BaseFieldType} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

export type RadioFieldType<ActionParam> = {
    type: RadioFieldValidType;
    input: {
        name: string;
        value: boolean;
    };
} & BaseFieldType<ActionParam>;

export const radioFieldValidTypes = stringTuple('radio');
export type RadioFieldValidType = typeof radioFieldValidTypes[number];

type Props<ActionParam> = {
    field: RadioFieldType<ActionParam>;
} & ChildProps;

class Radio<ActionParam> extends Component<Props<ActionParam>> {
    render(): ReactNode {
        const {className, field} = this.props;
        const {
            label,
            helpText,
            input,
            meta,
            disabled,
        } = field;

        const {asyncValidating, submitting} = meta;

        const classes = classNames('form-check', className);
        const id = `${input.name}--${input.value}`;

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>

                <input
                    type="radio"
                    className="form-check-input"
                    id={id}
                    disabled={asyncValidating || submitting || disabled}
                    name={input.name}
                />

                <Label htmlFor={id} className="form-check-label">
                    {label}
                </Label>

                <div className="check-radio" />
            </div>
        );
    }
}

export default Radio;
