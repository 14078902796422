import {omit} from 'lodash';
import * as React from 'react';

import classNames from 'utils/classNames';

import styles from './styles.sass';

interface Props {
    icon: string;
    className?: string | null;
}

const SvgIcon = (props: Props) => {
    const {icon, className} = props;

    const classes = classNames(className, 'd-inline-flex', 'align-items-center');

    return (
        <span
            {...omit(props, ['icon'])}
            className={`${styles['svg-icon']} ${classes}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: icon}}
        />
    );
};
SvgIcon.defaultProps = {
    className: null,
};

export default SvgIcon;
