import {range} from 'lodash';
import * as React from 'react';

import {Components as Icons} from 'icons';
import classNames from 'utils/classNames';
import chevronLeftSvg from './chevronLeft.svg';
import chevronRightSvg from './chevronRight.svg';
import chevronsLeftSvg from './chevronsLeft.svg';
import chevronsRightSvg from './chevronsRight.svg';

import styles from './styles.sass';

interface Props {
    totalPages: number;
    currentPage: number;
    pagePadding: number;
    className?: string;

    onPageChange(id: number): void;
}

const Pagination = (props: Props) => {
    const {
        currentPage,
        totalPages,
        pagePadding,
        className,
        onPageChange,
    } = props;
    const classes = classNames(className, 'pagination');

    const start = (currentPage - pagePadding > 0) ? currentPage - pagePadding : 1;
    const end = (currentPage + pagePadding < totalPages) ? currentPage + pagePadding : totalPages;
    const pages = range(start, end + 1);

    return (
        <nav className={styles.pagination}>
            <ul className={classes}>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(1)}
                        type="submit"
                    >
                        <Icons.SvgIcon icon={chevronsLeftSvg} />
                    </button>
                </li>

                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage - 1)}
                        type="submit"
                    >
                        <Icons.SvgIcon icon={chevronLeftSvg} />
                    </button>
                </li>

                {
                    pages[0] > 1
                    && (
                        <li className="page-item disabled">
                            <div className="page-link">
                                ...
                            </div>
                        </li>
                    )
                }

                {
                    pages.map((page) => (
                        <li
                            className={`page-item ${page === currentPage ? 'active' : ''}`}
                            key={page}
                        >
                            <button
                                className="page-link"
                                onClick={() => onPageChange(page)}
                                type="submit"
                            >
                                {page}
                            </button>
                        </li>
                    ))
                }

                {
                    pages[pages.length - 1] < totalPages
                    && (
                        <li className="page-item disabled">
                            <div className="page-link">
                                ...
                            </div>
                        </li>
                    )
                }

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage + 1)}
                        type="submit"
                    >
                        <Icons.SvgIcon icon={chevronRightSvg} />
                    </button>
                </li>

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(totalPages)}
                        type="submit"
                    >
                        <Icons.SvgIcon icon={chevronsRightSvg} />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

Pagination.defaultProps = {
    currentPage: 1,
    pagePadding: 2,
    className: '',
};

export default Pagination;
