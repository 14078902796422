import * as React from 'react';
import {Component, ReactNode, SyntheticEvent} from 'react';

import {Components as Icons} from 'icons';
import {UploadPlanActionPayload} from '../../actions';
import plusSvg from './plus.svg';
import styles from './styles.sass';

interface Props {
    handleUploadPlan(_: UploadPlanActionPayload): void;
}

class UploadPlanButton extends Component<Props> {
    render(): ReactNode {
        const {
            handleUploadPlan,
        } = this.props;

        const onFileChange = (data: SyntheticEvent<HTMLInputElement>) => {
            const elem = data.currentTarget;
            if (!elem.files || !elem.files[0]) { throw new Error('Missing file on input.'); }
            const file = elem.files[0];
            if (file) {
                handleUploadPlan({plan: file});
                elem.value = '';
            }
        };

        return (
            <span className="mt-3 ml-3">
                <label
                    htmlFor="newPlan"
                    className={`btn btn-primary ${styles['label-wrapper']}`}
                >
                    <Icons.SvgIcon
                        icon={plusSvg}
                        className={`mr-2 ${styles['plus-icon']}`}
                    />
                    <span>Nahrát plán</span>
                </label>
                <input
                    type="file"
                    className="d-none"
                    id="newPlan"
                    onChange={onFileChange}
                />
            </span>
        );
    }
}

export default UploadPlanButton;
