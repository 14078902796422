import createHistory from 'history/createBrowserHistory';
import {routerMiddleware} from 'react-router-redux';
import {applyMiddleware, compose, createStore, StoreEnhancer} from 'redux';
import createSagaMiddleware from 'redux-saga';

import {initialLayoutState} from 'layout/reducer';
import {CustomWindow} from 'types/window';
import {initialUserState} from 'user/reducer';
import {CustomModule} from '../types/customModule';
import {Action} from './actions';
import reducer from './reducer';
import sagas from './sagas';
import {StoreState} from './types/StoreState';

const composeEnhancers: StoreEnhancer = (window as CustomWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const defaultState: Partial<StoreState> = {
    user: initialUserState,
    layout: initialLayoutState,
};
const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routerReduxMiddleware = routerMiddleware(history);
const middlewares = applyMiddleware(sagaMiddleware, routerReduxMiddleware);
const store = createStore<StoreState, Action, any, void>(
    reducer,
    defaultState,
    composeEnhancers(middlewares as any) as any,
);

const customModule = module as unknown as CustomModule;
if (customModule.hot) {
    // Enable Webpack hot module replacement for reducers
    customModule.hot.accept('./reducer', () => {
        const nextReducer = require('./reducer').default; // eslint-disable-line global-require
        store.replaceReducer(nextReducer);
    });
}

sagaMiddleware.run(sagas);

export {store, history};
