import * as t from 'io-ts';

export class FormDataType extends t.Type<FormData> {
    //  tslint:disable-next-line: variable-name
    readonly _tag: 'FormDataType' = 'FormDataType';

    constructor() {
        super(
            'FormData',
            (m: t.mixed): m is FormData => m instanceof FormData,
            (m, c) => (this.is(m) ? t.success(m) : t.failure(m, c)),
            t.identity,
        );
    }
}

export const FormDataSchema = new FormDataType();
