import {ErrorFromBE} from '../types/ErrorFromBE';
import {User} from '../types/User';

export interface LoadCurrentAction {
    type: 'user/LOAD_CURRENT';
}

export const loadCurrent = (): LoadCurrentAction => ({
    type: 'user/LOAD_CURRENT',
});

export interface LoadCurrentSuccessAction {
    type: 'user/LOAD_CURRENT_SUCCESS';
    payload: User;
}

export const loadCurrentSuccess = (user: User): LoadCurrentSuccessAction => ({
    type: 'user/LOAD_CURRENT_SUCCESS',
    payload: user,
});

export interface LoadCurrentErrorAction {
    type: 'user/LOAD_CURRENT_ERROR';
    payload: ErrorFromBE;
}

export const loadCurrentError = (err: ErrorFromBE): LoadCurrentErrorAction => ({
    type: 'user/LOAD_CURRENT_ERROR',
    payload: err,
});

// ---

export interface LoginAction {
    type: 'user/LOGIN';
}

export const login = (): LoginAction => ({
    type: 'user/LOGIN',
});

export interface LoginSuccessAction {
    type: 'user/LOGIN_SUCCESS';
}

export const loginSuccess = (): LoginSuccessAction => ({
    type: 'user/LOGIN_SUCCESS',
});

export interface LoginErrorAction {
    type: 'user/LOGIN_ERROR';
    payload: ErrorFromBE;
}

export const loginError = (err: ErrorFromBE): LoginErrorAction => ({
    type: 'user/LOGIN_ERROR',
    payload: err,
});

// ---

export interface LogoutAction {
    type: 'user/LOGOUT';
}

export const logout = (): LogoutAction => ({
    type: 'user/LOGOUT',
});

// ---

export type UserAction =
    LoadCurrentAction | LoadCurrentSuccessAction | LoadCurrentErrorAction |
    LoginAction | LoginSuccessAction | LoginErrorAction |
    LogoutAction;
