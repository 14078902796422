import * as t from 'io-ts';

import {RatingTypeSchema} from './RatingType';

const optionalPart = t.partial({
    ratingType: RatingTypeSchema,
});
const requiredPart = t.interface({
    breedingId: t.string,
    stableName: t.string,
    companyName: t.string,
});

export const NewStableSchema = t.exact(t.intersection([
    optionalPart,
    requiredPart,
]));

export interface NewStable extends t.TypeOf<typeof NewStableSchema> {}
