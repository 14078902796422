import * as t from 'io-ts';

const optionalPart = t.partial({
    firstname: t.string,
    lastname: t.string,
    totalAnimals: t.number,
    measuredAnimals: t.number,
    stablesCount: t.number,
    importedDate: t.string,
    updatedDate: t.string,
});

const requiredPart = t.interface({});

export const ActivePlanSchema = t.exact(t.intersection([
    optionalPart,
    requiredPart,
]));

export interface ActivePlan extends t.TypeOf<typeof ActivePlanSchema> {}
